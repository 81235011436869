
import BButton from 'buetify/lib/components/button/BButton';
import BContainer from 'buetify/lib/components/layout/container/BContainer';
import BHero from 'buetify/lib/components/layout/hero/BHero';
import BHeroBody from 'buetify/lib/components/layout/hero/BHeroBody';
import BTitle from 'buetify/lib/components/title/BTitle';
import { defineComponent } from 'vue';
import BuetifyDocumentationNavbar from '../../components/buetifyNavbar/BuetifyDocumentationNavbar.vue';
import SadTearIcon from '../../components/icons/SadTearIcon';

export default defineComponent({
	name: 'not-found',
	components: {
		BuetifyDocumentationNavbar,
		BHero,
		BHeroBody,
		BContainer,
		SadTearIcon,
		BTitle,
		BButton
	}
});
